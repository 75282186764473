'use strict'

angular.module('cb.webenquiry-service', [])
    .factory('WebEnquiryService', ["ApiService", function (ApiService) {

        var webEnquiryService = {
            getUnprocessedEnquiry: function (data) {
                try {
                    var url = '/webapi/api/v1/webenquiry/getUnprocessedEnquiry';
                    return ApiService.promisePost(url,data);
                }
                catch (e) {
                    console.error('Error!', e);
                }
            },

            getWebEnquiryById: function (id) {
                try {
                    var url = '/webapi/api/v1/webenquiry/viewEnquiry/' + id;
                    return ApiService.promiseGet(url);
                }
                catch (e) {
                    console.error('Error!', e);
                }
            },

            searchForDuplicate: function (id) {
                try {
                    var url = '/webapi/api/v1/webEnquiry/searchForDuplicate/'+id;
                    return ApiService.promiseGet(url);
                }
                catch (e) {
                    console.error('Error!', e);
                }
            },

            saveAsLead: function (id) {
                try {
                    var url = '/webapi/api/v1/webEnquiry/saveAsLead/'+id;
                    return ApiService.promiseGet(url);
                }
                catch (e) {
                    console.error('Error!', e);
                }
            },

            removeRecord: function (id) {
                try {
                    var url = '/webapi/api/v1/webEnquiry/removeRecord/'+id;
                    return ApiService.promiseGet(url);
                }
                catch (e) {
                    console.error('Error!', e);
                }
			},

			updateWebEnquiry: function (data) {
				try {
					var url = '/webapi/api/v1/webEnquiry/updateWebEnquiry';
					return ApiService.promisePost(url, data);
				}
				catch (e) {
					console.error('Error!', e);
				}
			}
        };

        return webEnquiryService;
    }]);
